import React from "react"
import { graphql } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import Slider from "../components/slider-courses"
import AllCourses from "../components/cards/all-courses"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import TitleBanner from "../components/titleBanner/TitleBanner"
import CardsList from "../components/common/cardsList/CardsList"
import Reviews from "../components/product-detail/itemReviews/Reviews"

let CoursesBentley = ({ data }) => {
  const { bentleyCourses } = data
  return (
    <>
      <SEO
        title="Bentley Software Courses"
        description="Get started with BIM Courses on Synchro 4D, ProjectWise365, MicroStation, OpenBuildings, and more"
      />

      <TitleBannerEnquiry
        title="Bentley Software Courses"
        description="Get started with BIM Courses on Synchro 4D, ProjectWise365, MicroStation, OpenBuildings, and more"
        withOnlyDescription
      />

      <div className="container">
        <CardsList
          courses={bentleyCourses.nodes}
          withoutFilter
        />
        {/* <div className="reviews-cover">
          <Reviews />
        </div> */}
      </div>
    </>
  )
}

export const bentleyQuery = graphql`
  query allBentleyQuery {
    bentleyCourses: allContentfulTraining(
      filter: { category: { eq: "bentley" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesBentley
